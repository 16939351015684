<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> マスタ管理 - 資料</h1>
    </header>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <div v-else class="col-sm-36 col-md-34 col-lg-32">
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-1"></th>
                        <th class="text-center col-12">資料名</th>
                        <th class="text-center col-14">資料URL</th>
                        <th class="text-center"></th>
                    </tr>
                </thead>
                <template v-if="documents.length">
                    <draggable
                            v-model="documents"
                            item-key="document_id"
                            tag="tbody"
                            handle=".handle"
                            @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle">
                                    <form-input required v-model="element.document_name" />
                                </td>
                                <td class="align-middle">
                                    <form-input v-model="element.document_url" />
                                </td>
                                <td class="text-center">
                                    <button type="button" class="btn btn-info me-2" @click="update(element)">保存</button>
                                    <button type="button" class="btn btn-outline-danger ms-2" @click="removeReady(element)">削除</button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="align-middle"><form-input required v-model="document_to_create.document_name" /></td>
                        <td class="align-middle"><form-input v-model="document_to_create.document_url" /></td>
                        <td class="text-center">
                            <button class="btn btn-info" @click="create">追加</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import Document from '@/models/entities/document';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';

export default {
    name: 'MasterDocument',
    components: {
        draggable,
        InlineLoader,
        FormInput,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading: false,
            // 表示データ
            documents: [],
            // 新規
            document_to_create: new Document(),
            // 削除
            document_to_remove: null,
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        // 一覧取得
        search() {
            //表示データ初期化
            this.documents.splice(0);
            //ローディング開始
            this.loading = true;
            this.$http.get('/master/document')
            .then(response => {
                for (let row of response.data) {
                    this.documents.push(new Document(row));
                }
            })
            .finally(() => {
                //ローディング終了
                this.loading = false;
            });
        },
        // 新規登録
        create() {
            // バリデーション
            if (this.$helper.isBlank(this.document_to_create.document_name)) {
                this.showErrorMessage('資料名を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.post('/master/document', this.document_to_create)
            .then(response => {
                this.documents.push(new Document(response.data));
                this.document_to_create = new Document();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 保存（編集）
        update(document) {
            // バリデーション
            if (this.$helper.isBlank(document.document_name)) {
                this.showErrorMessage('資料名を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/master/document/${document.document_id}`, document)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 削除モーダル
        removeReady(document) {
            this.document_to_remove = document;
            this.$refs.confirmRemove.show();
        },
        // 削除処理
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/master/document/${this.document_to_remove.document_id}`)
            .then(() => {
                const remove_index = this.documents.findIndex((row) => row.document_id === this.document_to_remove.document_id);
                this.documents.splice(remove_index, 1);
                this.document_to_remove = null;
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 並び順更新
        sort() {
            this.startScreenLoading();

            const documents = this.documents.map(document_object => document_object.document_id);

            this.$http.post('/master/document/sort', {
                documents: documents,
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>
