/**
 * 資料
 */
import { isBlank } from '@/utilities/typing'

class Document {
    document_id;
    document_name;
    document_url;
    priority;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.document_id = properties.document_id;
        this.document_name = properties.document_name;
        this.document_url = properties.document_url;
        this.priority = properties.priority;
    }
}

export default Document;
